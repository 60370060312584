import { toggleMenu } from './toggleMenu';

export const initMobileMenu = () => {
    // VARS
    const buttonMenu = document.querySelector('[data-button-menu]');
    const buttonDownload = document.querySelector('[data-button-download]');
    const buttonLanguages = document.querySelector('[data-button-languages]');
    const menu = document.querySelector('[data-menu]');
    const download = document.querySelector('[data-download]');
    const language = document.querySelector('[data-languages]');

    const elements = [buttonMenu, buttonDownload, buttonLanguages];

    // LISTENERS
    elements.forEach((element) => {
        element.addEventListener('click', handlerToggleMenu);
    });

    document.addEventListener('click', handlerCloseMenu);

    // HANDLRES
    function handlerToggleMenu(event) {
        event.stopPropagation();

        toggleMenu(buttonDownload, download);
        toggleMenu(buttonMenu, menu);
        toggleMenu(buttonLanguages, language);
    }

    function handlerCloseMenu(event) {
        const isMenuClicked = menu.contains(event.target) || event.target.closest('[data-menu]');
        const isDownloadClicked = download.contains(event.target) || event.target.closest('[data-download]');
        const isLanguageClicked = language.contains(event.target) || event.target.closest('[data-languages]');

        if (!isMenuClicked && !isDownloadClicked && !isLanguageClicked) {
            [language, menu, download].forEach((element) => element.classList.remove('active-menu'));
        }
    }
};
