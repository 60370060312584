export const initJivoChat = () => {
    if (!Boolean(window.locationtrackingAiJivoChatHash)) return;

    // VARS
    const delay = 5000;
    const url = `//code.jivosite.com/widget/${window.locationtrackingAiJivoChatHash}`;
    const script = document.createElement('script');

    // INITS
    script.src = url;
    script.setAttribute('async', '');

    setTimeout(() => {
        document.head.appendChild(script);
    }, delay);
};
