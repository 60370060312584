import { initMobileMenu } from './mobile-menu/initMobileMenu';
import { initScrollToAnchor } from './utils/initScrollToAnchor';
import { initSwiperAbout } from './components/initSwiperAbout';
import { initJivoChat } from './components/initJivoChat';

window.addEventListener('DOMContentLoaded', onLoad, false);

function onLoad() {
    initMobileMenu();
    initScrollToAnchor();
    initSwiperAbout();
    initJivoChat();
}
