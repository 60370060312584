import Swiper from 'swiper/bundle';

export function initSwiperAbout() {
    const swiper = new Swiper('.swiper', {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 'auto',
        navigation: {
            nextEl: '[data-about-next]',
            prevEl: '[data-about-prev]',
        },
    });
}
